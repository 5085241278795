<template>
  <div>
    <ReportCenterTitle v-if="!inside" title="productRevenues" :count="count" />
    <h6 class="mt-2" v-else>
      <router-link to="/productRevenues"
        >{{ $t("productRevenues") }} ({{ count }})</router-link
      >
    </h6>
    <Table
      :hideHeader="inside"
      :count="count"
      :headers="headers"
      no-search
      :loading="isLoading"
      :items="items"
      no-card
    >
      <template #studentCount="{ item }">
        <span class="text-info">
          <i class="icon-user"></i> {{ item.studentCount }}</span
        >
      </template>
      <template #averageQuizScore="{ item }">
        <span :class="courseRateClass(item.averageQuizScore)">
          <i class="icofont-medal"></i> {{ item.averageQuizScore }}%</span
        >
      </template>
      <template #totalPrice="{ item }">
        <span>
          {{ Currency({ price: item.totalPrice }) }}
        </span>
      </template>
    </Table>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import ReportCenterTitle from "../Title.vue";
import Table from "../../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";

export default {
  components: {
    BoostrapPagination,
    ReportCenterTitle,
    Table,
  },
  props: {
    inside: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 10,
    },
  },
  data() {
    return {
      items: [],
      isLoading: true,
      count: 0,
      page: 1,
      //       "averageStudyTime": 0,
      // "averageQuizScore": 0,
      // "studentCount": 0,
      // "certificateCount": 0,
      // "averageCommentScore": 0,
      // "totalPrice": 3540
      headers: [
        {
          title: this.$t("productName"),
          value: "courseTitle",
          col: 12,
          md: 6,
          lg: 6,
          xl: 6,
        },
        {
          title: this.$t("averageStudyTime"),
          value: "averageStudyTime",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("averageQuizScore"),
          value: "averageQuizScore",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("studentCount"),
          value: "studentCount",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("certificateCount"),
          value: "certificateCount",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("totalPrice"),
          value: "totalPrice",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    formatter,
    Currency,
    courseRateClass(rate) {
      if (rate > 85) return "text-success";
      else if (rate > 70) return "text-info";
      else if (rate > 50) return "text-warning";
      else return "text-danger";
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
      this.GET_ITEMS();
    },
    async GET_ITEMS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Statistic/GetProductStatisticDetails?page=${this.page}&limit=${this.limit}&orderBy=student`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(Number(q.page))) this.page = Number(q.page);
    this.GET_ITEMS();
  },
};
</script>

<style></style>
